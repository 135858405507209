import logo from './logo.svg';
import './App.css';

import Navbar from './Navbar';
import { Link } from 'react-router-dom';

function App() {
return (
    <div className="App">
        <Navbar />
        <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <h1>
                    Welcome to the Lionhearts Calculator
            </h1>
            <h2 className="App-subtitle">Select a page below to get started</h2>
            <div className="button-row">
                    <Link to="/ingots" className="App-link">
                            <button>Ingots</button>
                    </Link>
                    <Link to="/xp" className="App-link">
                            <button>XP</button>
                    </Link>
                    <Link to="/region-battles" className="App-link">
                            <button>Region Battles</button>
                    </Link>
            </div>
        </header>
    </div>
);
}

export default App;
