import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1 style={{color: 'white'}}>404</h1>
            <h2 style={{color: 'white'}}>Page Not Found</h2>
            <p style={{color: 'white'}}>AVE ROMA. FOR THE EMPEROR.</p>
            <Link to="/" style={{color: 'yellow', border: '1px solid white', padding: "5px"}}>HOME</Link>
        </div>
    );
};

export default PageNotFound;