const ingotMaterials = {
    arsenicalBronze: {
        "copper": 3,
        "arsenopyrite": 5,
    },
    bronze: {
        "copper": 6,
        "cassiterite": 3,
    },
    wroughtIron: {
        "arsenopyrite": 2,
        "siderite": 5,
        "cassiterite": 2,
    },
    castIron: {
        "limonite": 3,
        "hematite": 3,
        "magnetite": 2,
        "wroughtIron": 1,
    },
    steel: {
        "castIron": 4,
        "coal": 22,
    },
    foldedDamascus: {
        "meteoricIron": 2,
        "coal": 35,
        "steel": 3,
    },
    wootz: {
        "limonite": 3,
        "hematite": 3,
        "arsenopyrite": 10,
        "bronze": 10,
        "foldedDamascus": 2,
        "ilmenite": 2,
    },
    castDamascus: {
        "foldedDamascus": 6,
        "meteoricIron": 12,
        "wootz": 6,
        "coal": 500,
        "ilmenite": 6,
    }
}

function getMaterials(materials) {
    // console.log("calculating materials: ", materials);
    let returnList = {};
    for (let material in materials) {
        let mult = materials[material];
        // console.log(material + ": " + materials[material]);
        if (!ingotMaterials[material]) {
            // console.log("Not an ingot: ", material);
            // returnList.push({[material]: materials[material]});
            returnList[material] = returnList[material] ? returnList[material] + (materials[material]) : materials[material];
        } else {
            // console.log("Is an ingot: ", material, ingotMaterials[material]);
            let ingredients = ingotMaterials[material];
            for (let ingredient in ingredients) {
                let temp = getMaterials({[ingredient]: ingredients[ingredient]});
                
                for (let item in temp) {
                    // returnList.push(temp);
                    returnList[item] = returnList[item] ? returnList[item] + (temp[item] * mult) : temp[item] * mult;  
                }
            }
        }
        // console.log("current list: ", returnList)
    }


    return returnList;
}

function getIngotMaterials(ingotType, num, unraw) {
    // console.log("ingotType: ", ingotType);
    // console.log("num: ", num);
    // console.log(ingotMaterials[ingotType]);
    if (!ingotMaterials[ingotType]) return {}; 

    if (unraw) return ingotMaterials[ingotType];

    return getMaterials({[ingotType]: num});

    // let mats = getMaterials({[ingotType]: num});
    // console.log("mats: ", mats);

    // return ingotMaterials[ingotType];
}

export {getIngotMaterials};