import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';

import './RegionBattle.css'
import { getDiscordRbs, getNextRb } from './calculators/rbCalculator';

const RegionBattle = () => {
    const [nextBattleTime, setNextBattleTime] = useState(0); // 1 hour from now
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(nextBattleTime));

    useEffect(() => {
        setNextBattleTime(new Date(getNextRb() * 1000));
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft(nextBattleTime));
        }, 1000);

        return () => clearInterval(timer);
    }, [nextBattleTime]);

    function handleCopy() {
        const numRBs = document.getElementById('numRBs').value;
        const textToCopy = getDiscordRbs(numRBs);
        navigator.clipboard.writeText(textToCopy).then(() => {
            alert('Copied to clipboard');
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    }

    function calculateTimeLeft(nextBattleTime) {
        const difference = +new Date(nextBattleTime) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    }

    return (
        <>
        <Navbar />
        <div className='Rb-div'>
            <h1>Region Battle Information</h1>
            <p>This tool is to assist with planning of region battles. Get live times or copypaste tools for discord.</p>
            <div id='next'>
                <h2>Next Region Battle</h2>
                <p>
                    {nextBattleTime.toLocaleString()} (
                    {String(timeLeft.hours).padStart(2, '0') || '00'}:
                    {String(timeLeft.minutes).padStart(2, '0') || '00'}:
                    {String(timeLeft.seconds).padStart(2, '0') || '00'}
                )
                </p>
            </div>
            <div id='discord'>
                <h2>Discord Printer</h2>
                <label htmlFor="numRBs">No. Of RBs:</label>
                <input
                    type="number"
                    id="numRBs"
                    name="numRBs"
                    min="1"
                    defaultValue="1"
                />
                <button onClick={handleCopy}>Copy</button>
            </div>
        </div>
        </>
    );
};

export default RegionBattle;