function getNextRb() {
    let valid_epoch = 1723987800
    while (valid_epoch < Math.floor(Date.now() / 1000)) {
        valid_epoch += 48600;
    }
    return valid_epoch;
}

function getDiscordRbs(rbCount) {
    const nextRb = getNextRb();

    let outString = "";
    for (let i = 0; i < rbCount; i++) {
        const rbStartEpoch = nextRb + (i * 48600);
        outString += `<t:${rbStartEpoch}:d>  :  <t:${rbStartEpoch}:D> - <t:${rbStartEpoch}:t> - Starts <t:${rbStartEpoch}:R> \n`;
        if (i % 2 === 1) {
            outString += '\n';
        }
    }

    return outString;
}

export {getNextRb, getDiscordRbs};


// def run_calculator(start_epoch, number_of_battles):
//     print('Running calculator...')
//     out_string = ''
//     for i in range(number_of_battles):
//         print(f'Calculating battle {i + 1}...')
//         rb_start_epoch = start_epoch + (i * 48600)
//         out_string += f'<t:{rb_start_epoch}:d>  :  <t:{rb_start_epoch}:D> - <t:{rb_start_epoch}:t> - Starts <t:{rb_start_epoch}:R> \n'
//         if i % 2 == 1:
//             out_string += '\n'


//     print("Finished calculating...")
//     print(out_string)
//     pyperclip.copy(out_string)