import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import './Xp.css';

const xp_levels = {
    // Current Level: [XP To Next, Cumulative XP to next]
    "-1": [0,0],
    0: [10, 10],
    1: [95, 105],
    2: [320, 425],
    3: [720, 1145],
    4: [1330, 2475],
    5: [2170, 4645],
    6: [3255, 7900],
    7: [4625, 12525],
    8: [6275, 18800],
    9: [8250, 27050],
    10: [10535, 37585],
    11: [13160, 50745],
    12: [16135, 66880],
    13: [19485, 86365],
    14: [23215, 109580],
    15: [27420, 137000],
    16: [31000, 168000],
    17: [37500, 205500],
    18: [41500, 247000],
    19: [48000, 295000],
    20: [55000, 350000],
    21: [60745, 410745],
    22: [68255, 479000],
    23: [75500, 554500],
    24: [84000, 638500],
    25: [92425, 730925],
    26: [101575, 832500],
    27: [112500, 945000],
    28: [120000, 1065000],
    29: [133000, 1198000],
    30: [142000, 1340000],
    31: [160000, 1500000],
    32: [163750, 1663750],
};

const XpPage = () => {

    const [inputs, setInputs] = useState({
        currentLevel: 0,
        currentXp: 0,
        targetLevel: 0,
        xpPerMinute: 0
    });

    useEffect(() => {
        // console.log("New Inputs: ", inputs)
    }, [inputs]);

    function updateXp(event) {
        const element = event.target; // The element that triggered the event
        const elementId = element.id; // Get the ID of the element
        const elementValue = element.value; // Get the value of the element
    
        if (isNaN(elementValue)) return;
        
        // console.log("Element ID:", elementId);
        // console.log("Element Value:", elementValue);

        setInputs(prevInputs => ({
            ...prevInputs,
            [elementId]: parseInt(elementValue)
        }));
    };

    return (
        <>
            <Navbar />
            <div className='Xp-div'>
                <h1>Xp Page</h1>
                <p>A basic setup, this allows for quick and simple calculations of XP required, and ETAs.</p>
                <details className='Clickable-details'>
                    <summary>Full list of XP per level:</summary>
                    <table className='Xp-table'>
                        <thead>
                            <tr>
                                <th>Level</th>
                                <th>XP</th>
                                <th>Cumulative XP</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.from({ length: 33 }, (_, i) => (
                                <tr key={i + 1}>
                                    <td>{i}-&gt;{i + 1}</td>
                                    <td>{xp_levels[i][0]}</td>
                                    <td>{xp_levels[i][1]}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </details>
                <hr/>
                <div className='Xp-inputs'>
                    <label>
                        Current Level:
                        <input id='currentLevel' type='number' min='0' max='33' onChange={updateXp}/>
                    </label>
                    <br />
                    <label>
                        Current XP:
                        <input id='currentXp' type='number' min='0' onChange={updateXp}/>
                    </label>
                    <br />
                    <label>
                        Target Level:
                        <input id='targetLevel' type='number' min={Math.max(1,inputs.currentLevel)} max='33' onChange={updateXp} />
                    </label>
                    <br />
                    <label>
                        XP per Minute:
                        <input id='xpPerMinute' type='number' min='0' onChange={updateXp} />
                    </label>
                </div>
                <hr/>
                <div className='Xp-calculations'>
                    <p>Remaining XP: {xp_levels[inputs.targetLevel-1][1] - (xp_levels[inputs.currentLevel-1][1] + inputs.currentXp)}</p>
                    {(!(isNaN(inputs.xpPerMinute)) && inputs.xpPerMinute > 0 &&
                    <p>Minutes to Finish: {(xp_levels[inputs.targetLevel-1][1] - (xp_levels[inputs.currentLevel-1][1] + inputs.currentXp)) / inputs.xpPerMinute}</p>
                    )}
                    <p>Current Total XP: {xp_levels[inputs.currentLevel-1][1] + inputs.currentXp}</p>
                    <p>Target Total XP: {xp_levels[inputs.targetLevel-1][1]}</p>
                </div>
            </div>
        </>
    );
};

export default XpPage;