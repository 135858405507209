import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Navbar from './Navbar';
import { getIngotMaterials } from './calculators/materialCalculator.js';
import './Ingots.css';


function formatMaterial(material) {
    return material
        .replace(/([A-Z])/g, ' $1') // Add space before each capital letter
        .replace(/^./, str => str.toUpperCase()); // Capitalize the first letter
}

const Ingots = () => {
    const [selectedMaterial, setSelectedMaterial] = useState("wootz");
    const [materialDictionary, setMaterialDictionary] = useState();
    const [quantity, setQuantity] = useState(1);
    const [storage, setStorage] = useState({});

    useEffect(() => {
        const previousIngotSettings = Cookies.get('ingotSettings');
        if (previousIngotSettings) {
            const { selectedMaterial, quantity } = JSON.parse(previousIngotSettings);
            // console.log("Got previous settings: ", selectedMaterial, quantity);
            setSelectedMaterial(selectedMaterial);
            setQuantity(quantity);
            setMaterialDictionary(getIngotMaterials(selectedMaterial, quantity));
        }

        const storedStorage = Cookies.get('storage');
        // console.log("Stored resources dict: ", storedStorage);
        if (storedStorage) {
            setStorage(JSON.parse(storedStorage));
        }    
    }, [])

    const updateCookies = () => {
        // console.log("Updating cookies with: ", selectedMaterial, quantity);
        Cookies.set('ingotSettings', JSON.stringify({ selectedMaterial, quantity }), { expires: 3650 });
    };

    const handleMaterialChange = (event) => {
        setSelectedMaterial(event.target.value);
    };

    const handleQuantityChange = (event) => {
        setQuantity(event.target.value);
    };

    const handleSubmit = () => {
        setMaterialDictionary(getIngotMaterials(selectedMaterial, quantity));
        updateCookies();
    };

    const handleStorageChange = (event) => {
        // Validity check
        const value = event.target.value === '' ? 0 : event.target.value;

        if (isNaN(value) || !Number.isInteger(parseFloat(value))) {
            return;
        }

        // Update storage
        const rowIndex = event.target.closest('tr').rowIndex - 1;
        const material = Object.keys(materialDictionary)[rowIndex];
        
        setStorage(prevStorage => {
            const newStorage = { ...prevStorage, [material]: parseInt(value, 10) }; // Ensure the value is stored as an integer
            Cookies.set('storage', JSON.stringify(newStorage), { expires: 3650 }); // Save the updated storage to cookies
            return newStorage;
        });
        
        var remaining = materialDictionary[material] - event.target.value;
        if (remaining < 0) {
            remaining = 0;
        }

    };

    return (
        <>
        <Navbar />
        <div className='Ingots-div'>
            <h1>Ingots</h1>
            <p>
                This page allows for fast calculations of raw materials required to craft ingots.
                <br/>
                Select the ingot type and quantity to calculate the required materials.
            </p>
            <div className='Ingot-selection' style={{ margin: '5px' }}>
                <select value={selectedMaterial} onChange={handleMaterialChange}>
                    <option value="castDamascus">Cast Damascus</option>
                    <option value="wootz">Wootz</option>
                    <option value="foldedDamascus">Folded Damascus</option>
                    <option value="steel">Steel</option>
                    <option value="castIron">Cast Iron</option>
                    <option value="wroughtIron">Wrought Iron</option>
                    <option value="bronze">Bronze</option>
                    <option value="arsenicalBronze">Arsenical Bronze</option>
                </select>
                <input type="number" value={quantity} onChange={handleQuantityChange} />
                <button onClick={handleSubmit}>Submit</button>
            </div>
            <hr/>
            <div className='Material-shortlists' style={{ margin: '5px' }}>
                {materialDictionary && (
                    <>
                    <details className='Material-fulllist'>
                        <summary style={{ cursor: 'pointer'  }}>
                            Material List
                        </summary>
                        <ul style={{marginTop: '0px'}}>
                            {Object.entries(materialDictionary).map(([material, qty]) => (
                                <li key={material}>{formatMaterial(material)}: {qty}</li>
                            ))}
                        </ul>
                    </details>
                    <details className='Material-remainglist'>
                        <summary style={{ cursor: 'pointer'  }}>
                            Remaining Materials
                        </summary>
                        <ul style={{marginTop: '0px'}}>
                            {Object.entries(materialDictionary).map(([material, qty]) => {
                                const remainingQty = storage[material] ? Math.max(qty - storage[material], 0) : qty;
                                return remainingQty > 0 && (
                                    <li key={material}>{formatMaterial(material)}: {remainingQty}</li>
                                );
                            })}
                        </ul>
                    </details>
                    </>
                )}
            </div>
            <div className='Material-checklist' style={{ margin: '5px' }}>
                {materialDictionary && (
                    <table>
                        <thead>
                            <tr>
                                <th>Material</th>
                                <th>Quantity</th>
                                <th>Storage</th>
                                <th>Remaining</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(materialDictionary).map(([material, qty]) => (
                                <tr key={material}>
                                    <td className="material-name">{formatMaterial(material)}</td>
                                    <td className="material-quantity">{qty}</td>
                                    <td className="material-storage">
                                        <textarea 
                                            rows="1" 
                                            value={storage[material] || 0}
                                            onChange={handleStorageChange} 
                                        />
                                    </td>
                                    <td className="material-remaining">
                                        {storage[material] ? Math.max(qty - storage[material], 0) : qty}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
        </>
    );
};

export default Ingots;
