import { Link } from "react-router-dom";

import "./Navbar.css";

function Navbar() {
  return (
    <>
      <nav className="navbar">
        <Link to="/">Home</Link>
        <Link to="/ingots">Ingots</Link>
        <Link to="/xp">XP</Link>
        <Link to="/region-battles">Region Battles</Link>
      </nav>
    </>
  );
}

export default Navbar;
